import React, { useContext } from 'react';
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import SEO from '../components/seo';
import { WrapperContext } from '../components/Layout'
import { getExcerpt } from '../core/utils'

export const pageQuery = graphql`
	query QueryActus($uid: String!) {
		prismicPage(uid: { eq: $uid }) {
			data {
				title{text},
        texte{raw, text},
        # image_featured{
        #   ...sharp
        # }
			}
		}
	}
`

const Actus = ({ data }) => {
  const _WrapperContext = useContext(WrapperContext)
  const { settings } = _WrapperContext
  const {
    title,
    texte
  } = data.prismicPage.data

  const excerpt = texte.text
    ? getExcerpt(texte.text, 200).shortText
    : settings.data.description.text
  return (
    <div className="actus">
      <SEO
        pageTitle={title.text}
        pageDescription={excerpt}
        page={true}
        template="template-actus"
      />

      <div className="row">
        <div className="col-md-6 col-xs-12"></div>
        <div className="col-md-6 col-xs-12">
          <div className="liste">
            {RichText.render(texte.raw)}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Actus;